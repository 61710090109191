<template>
  <v-container v-if="!_.isEmpty(officeEditable)">
    <!-- Banner header -->
    <Banner :title="'Oficina'" />

    <!-- Form profile data -->
    <v-card class="my-8 pa-5">
      <v-card-text class="pa-5">
        <v-form ref="formEditOffice" v-model="formEditOfficeValid">
          <v-text-field v-model="officeEditable.name" label="Nombre" :rules="formRules.textRules" :readonly="!editable" />
          <v-select
            v-model="officeEditable.partner_id"
            :items="partners"
            item-text="name"
            item-value="id"
            label="Partner"
            :rules="[(v) => v != '' || 'Campo obligatorio']"
            readonly
          />
          <CountryAutocomplete
            :listener="office"
            :countryId="officeEditable.country"
            :editable="editable"
            @selected="handleCountrySelection($event)"
          />
          <v-autocomplete
            v-if="!_.isEmpty(officeEditable.country)"
            v-model="officeEditable.city"
            :items="cities"
            item-text="name"
            item-value="id"
            label="Ciudad"
            :rules="formRules.textRules"
            :readonly="!editable"
          />
          <v-text-field
            v-if="!_.isEmpty(officeEditable.city)"
            v-model="officeEditable.zone"
            label="Zona"
            :readonly="!editable"
            :rules="formRules.textRules"
          />
          <v-text-field v-model="officeEditable.address.name" label="Dirección" :rules="formRules.textRules" :readonly="!editable" />
          <v-text-field v-model="officeEditable.schedule" label="Horario" :readonly="!editable" />
          <h3 class="mb-2">Cuentas bancarias:</h3>
          <v-row>
            <v-col v-for="(account, i) in officeEditable.accounts" :key="i" cols="12" md="4">
              <v-toolbar dense flat class="secondary pl-n5">
                <p class="mb-0">Cuenta {{ i + 1 }}:</p>
                <v-spacer />
                <v-btn
                  v-if="account.type !== 'FEES' && account.type !== 'OUTLAYS' && editable"
                  icon
                  elevation="2"
                  small
                  color="red"
                  class="mr-1"
                  @click="removeAccount(i)"
                >
                  <v-icon small>delete</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text class="secondary">
                <v-text-field v-model="account.bank" label="Banco" :readonly="!editable" :rules="formRules.textRules" />
                <v-text-field v-model="account.number" label="Número de cuenta" :readonly="!editable" :rules="formRules.textRules" />
                <v-select
                  v-if="account.type !== 'FEES' && account.type !== 'OUTLAYS' && editable"
                  v-model="account.type"
                  :items="['FEES', 'OUTLAYS', 'EXPENSES', 'OTHER']"
                  :rules="[(v) => v != '' || 'Campo obligatorio']"
                  label="Tipo"
                />
                <v-text-field v-else v-model="account.type" label="Tipo" readonly />
                <v-text-field
                  v-model.number="account.balance"
                  label="Saldo disponible"
                  :readonly="!editable"
                  :rules="formRules.numberRulesAllowZero"
                />
                <v-text-field v-model.number="account.bail" label="Fianza" :readonly="!editable" :rules="formRules.numberRulesAllowZero" />
                <v-text-field v-model="account.clabe" label="CLABE" :readonly="!editable" />
              </v-card-text>
            </v-col>
          </v-row>
          <div v-if="editable" class="mt-3">
            <v-btn elevation="2" small @click="addAccount">
              <v-icon>add</v-icon>
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-icon v-if="!editable && permissions.profileData.edit.includes(user.role)" @click="editable = true">edit</v-icon>
        <v-btn v-if="editable" text @click="(editable = false), formatFields()">Cancelar</v-btn>
        <v-btn v-if="editable" text @click="edit">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import listeners from "@/firebase/listeners";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import _ from "lodash";
import CommonMixin from "@/mixins/CommonMixin";
import Banner from "@/components/elements/Banner";
import CountryAutocomplete from "@/components/elements/locations/CountryAutocomplete.vue";

export default {
  name: "Office",
  components: {
    Banner,
    CountryAutocomplete
  },
  mixins: [FormRulesMixin, FormatDateMixin, CommonMixin],
  data() {
    return {
      // Cards actions permissions
      permissions: {
        profileData: {
          edit: ["SUPERADMIN", "ADMIN"]
        }
      },

      // Form edit office
      editable: false,
      formEditOfficeValid: false,
      officeEditable: {}
    };
  },
  computed: {
    ...mapState({
      office: (state) => state.offices.office,
      partners: (state) => state.partners.partners,
      user: (state) => state.user.user,
      countries: (state) => state.locations.countries,
      cities: (state) => state.locations.cities
    }),
    _() {
      return _;
    }
  },
  watch: {
    office() {
      this.formatFields();
    },
    editable() {
      this.$store.commit("SET_EDITING", this.editable);
    }
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await listeners.office(this.$route.params.id);
    // Push back if id does not exist
    if (this._.isEmpty(this.$store.state.offices.office) || this.$store.state.offices.office.id !== this.$route.params.id) {
      this.$router.push("/oficinas");
      this.$store.commit("SET_LOADING", false);
      return;
    }
    await this.$store.dispatch("partners/getPartners");
    this.formatFields();
    this.$store.commit("SET_LOADING", false);
  },
  methods: {
    ...mapActions({
      editOffice: "offices/editOffice"
    }),

    // Format fields editables
    async formatFields() {
      const officeFromListener = this.$store.state.offices.office;
      this.officeEditable = this._.cloneDeep(officeFromListener);
    },

    // Edit office
    async edit() {
      if (this.$refs.formEditOffice.validate()) {
        let officeEditableFormattedData = {
          id: this.officeEditable.id,
          accounts: this.officeEditable.accounts,
          address: this.officeEditable.address,
          city: this.officeEditable.city,
          name: this.officeEditable.name,
          schedule: this.officeEditable.schedule,
          zone: this.officeEditable.zone
        };

        console.log(officeEditableFormattedData);

        await this.editOffice(officeEditableFormattedData);

        this.formatFields();
        this.editable = false;
      }
    },

    // Add an account
    addAccount() {
      this.officeEditable.accounts.push({
        bank: "",
        number: "",
        clabe: ""
      });
    },

    // Remove an account
    removeAccount(index) {
      this.officeEditable.accounts.splice(index, 1);
    },

    async handleCountrySelection(countryId) {
      await this.$store.dispatch("locations/getCities", { filter: { country: countryId } });
      this.officeEditable.country = countryId;
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;
    }
  }
};
</script>
